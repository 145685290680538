import React, { useState, useEffect } from "react";
import productsData from "../products.json";
import "../assets/Styles/Cart.css";
import { useTranslation } from "react-i18next";

const CartPage = () => {
  const { t } = useTranslation();
  const [cartItems, setCartItems] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [whatsappUrl, setWhatsappUrl] = useState("");
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");

  useEffect(() => {
    const fetchCartData = () => {
      const cartData = JSON.parse(sessionStorage.getItem("cart")) || [];
      setCartItems(cartData);
    };
    fetchCartData();
  }, []);

  useEffect(() => {
    const calculateTotalPrice = () => {
      return cartItems.reduce((total, item) => {
        const product = getProductDetails(item.productId);
        return total + (product ? product.Price * item.qty : 0);
      }, 0);
    };

    const createCheckoutUrls = () => {
      const body =
        cartItems
          .map((item) => {
            const product = getProductDetails(item.productId);
            return product
              ? `${product.Name}: ${item.qty} x $${product.Price} = $${
                  item.qty * product.Price
                }`
              : "";
          })
          .join("\n") +
        `\n\n${t("cartPage.totalPriceLabel")}: $${totalPrice.toFixed(
          2
        )} Plus Delivery charges` +
        (name ? `\n\n${t("cartPage.nameLabel")}: ${name}` : "") +
        (email ? `\n${t("cartPage.emailLabel")}: ${email}` : "") +
        (address ? `\n${t("cartPage.addressLabel")}: ${address}` : "");

      const whatsappMessage = body.replace(/\n/g, "%0A");
      const whatsappUrl = `https://wa.me/70235639?text=${whatsappMessage}`;
      setWhatsappUrl(whatsappUrl);
    };

    setTotalPrice(calculateTotalPrice());
    createCheckoutUrls();
  }, [cartItems, totalPrice, name, email, address, t]);

  const getProductDetails = (id) => {
    const lang = sessionStorage.getItem("lng");
    const langIndex = lang === "fr" ? 1 : 0;
    return productsData[langIndex].find((product) => product.ID === id);
  };

  const handleQuantityChange = (productId, amount) => {
    setCartItems((prevCartItems) => {
      const updatedCart = prevCartItems.map((item) => {
        if (item.productId === productId) {
          return { ...item, qty: Math.max(1, item.qty + amount) };
        }
        return item;
      });
      sessionStorage.setItem("cart", JSON.stringify(updatedCart));
      return updatedCart;
    });
  };

  const handleRemoveItem = (productId) => {
    setCartItems((prevCartItems) => {
      const updatedCart = prevCartItems.filter(
        (item) => item.productId !== productId
      );
      sessionStorage.setItem("cart", JSON.stringify(updatedCart));
      return updatedCart;
    });
  };

  return (
    <div>
      <hr />
      {cartItems.length === 0 ? (
        <div>
          <p style={{ textAlign: "center" }}>
            {t("cartPage.emptyCartMessage")}
          </p>
          <br />
          <div className="center">
            <a href="/onlineShop">
              <button>{t("cartPage.shopButton")}</button>
            </a>
          </div>
        </div>
      ) : (
        <div>
          {cartItems.map((item) => {
            const product = getProductDetails(item.productId);
            return product ? (
              <div
                key={item.productId}
                style={{ borderBottom: "1px solid #ccc", padding: "10px 0" }}
              >
                <div
                  className="cartItems"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <img
                    src={product.Images[0]}
                    alt={product.Name}
                    style={{
                      width: "200px",
                      height: "200px",
                      objectFit: "cover",
                      marginRight: "10px",
                    }}
                  />
                  <h4 style={{ width: "20%" }}>{product.Name}</h4>
                  <p>${product.Price}</p>
                  <p>
                    {t("cartPage.quantityLabel")}: {item.qty}
                  </p>
                  <p>
                    {t("cartPage.totalPriceLabel")}: ${item.qty * product.Price}
                  </p>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div>
                      <button
                        className="itemButtons"
                        onClick={() => handleQuantityChange(item.productId, -1)}
                      >
                        -
                      </button>
                      <input
                        type="number"
                        className="itemsInput"
                        value={item.qty}
                        readOnly
                        style={{ width: "50px", textAlign: "center" }}
                      />
                      <button
                        className="itemButtons"
                        onClick={() => handleQuantityChange(item.productId, 1)}
                      >
                        +
                      </button>
                      <button
                        className="itemButtons"
                        onClick={() => handleRemoveItem(item.productId)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          x="0px"
                          y="0px"
                          width="25"
                          height="25"
                          viewBox="0,0,256,256"
                        >
                          <g
                            fill="#ffffff"
                            fillRule="nonzero"
                            stroke="none"
                            strokeWidth="1"
                            strokeLinecap="butt"
                            strokeLinejoin="miter"
                            strokeMiterlimit="10"
                            strokeDasharray=""
                            strokeDashoffset="0"
                            fontFamily="none"
                            fontWeight="none"
                            fontSize="none"
                            textAnchor="none"
                          >
                            <g transform="scale(8.53333,8.53333)">
                              <path d="M13,3c-0.26757,-0.00363 -0.52543,0.10012 -0.71593,0.28805c-0.1905,0.18793 -0.29774,0.44436 -0.29774,0.71195h-5.98633c-0.36064,-0.0051 -0.69608,0.18438 -0.87789,0.49587c-0.18181,0.3115 -0.18181,0.69676 0,1.00825c0.18181,0.3115 0.51725,0.50097 0.87789,0.49587h18c0.36064,0.0051 0.69608,-0.18438 0.87789,-0.49587c0.18181,-0.3115 0.18181,-0.69676 0,-1.00825c-0.18181,-0.3115 -0.51725,-0.50097 -0.87789,-0.49587h-5.98633c0,-0.26759 -0.10724,-0.52403 -0.29774,-0.71195c-0.1905,-0.18793 -0.44836,-0.29168 -0.71593,-0.28805zM6,8v16c0,1.105 0.895,2 2,2h14c1.105,0 2,-0.895 2,-2v-16z"></path>
                            </g>
                          </g>
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <p key={item.productId}>{t("cartPage.productNotFound")}</p>
            );
          })}
          <div style={{ marginTop: "20px" }}>
            <h3>
              {t("cartPage.totalPriceLabel")}: ${totalPrice.toFixed(2)}{" "}
              {t("cartPage.delivery")}
            </h3>
            <h5>{t("cartPage.charges")}</h5>
          </div>
          <br />
          <div className="center">
            <button onClick={() => setIsPopupVisible(true)}>
              {t("cartPage.checkoutButton")}
            </button>
          </div>
          <div
            className={`overlay ${isPopupVisible ? "active" : ""}`}
            onClick={() => setIsPopupVisible(false)}
          ></div>
          <div className={`popup ${isPopupVisible ? "active" : ""}`}>
            <div>
              <label>
                {t("cartPage.nameLabel")}:
                <input
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </label>
              <br />
              <label>
                {t("cartPage.emailLabel")}:
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </label>
              <br />
              <label>
                {t("cartPage.addressLabel")}:
                <input
                  type="text"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  required
                />
              </label>
              <div className="center">
                <a href={whatsappUrl} target="_blank" rel="noopener noreferrer">
                  <button type="submit">{t("cartPage.checkout")}</button>
                </a>
              </div>
            </div>
          </div>
        </div>
      )}
      <br />
    </div>
  );
};

export default CartPage;
