import useLanguage from "../hooks/useLanguage";
import specialOffers from "../assets/imgs/specialOffers.png";
import { useTranslation } from "react-i18next";
import "../assets/Styles/SpecialOffers.css";

function SpecialOffers() {
  const { t } = useTranslation();
  useLanguage();

  return (
    <div className="special-offers-container">
      <div className="special-offers-card">
        <img src={specialOffers} alt="" />
        <div className="special-offers-content">
          <h1>{t("specialOffers.title")}</h1>
          <a href="/">
            <button>{t("specialOffers.button")}</button>
          </a>
        </div>
      </div>
    </div>
  );
}

export default SpecialOffers;
