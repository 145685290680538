import React from "react";
import { Link } from "react-router-dom";
import "../assets/Styles/ContactUs.css";

function ContactUs() {
  return (
    <div className="contact-container">
      <main className="contact-row">
        <section className="col left">
          <div className="contactTitle">
            <h2>Get In Touch</h2>
          </div>

          <div className="contactInfo">
            <div className="iconGroup">
              {/* <div className="icon">
                <i className="fa fa-phone"></i>
              </div> */}
              <div className="details">
                <span>Phone</span>
                <a href="tel:96170235639">+961 70 235 639</a>
              </div>
            </div>

            <div className="iconGroup">
              {/* <div className="icon">
                <i className="fa-solid fa-envelope"></i>
              </div> */}
              <div className="details">
                <span>Email</span>
                <a href="mailto:info@lecaracoli-lb.com">
                  info@lecaracoli-lb.com
                </a>
              </div>
            </div>
          </div>

          <div className="socialMedia">
            <Link to="https://www.facebook.com/people/Le-Caracoli-lb/61552617209978/?mibextid=LQQJ4d">
              <i className="fa-brands fa-facebook-f"></i>
            </Link>
            <Link to="https://www.instagram.com/lecaracoli.lb?igsh=MWE2M3Ztem96cDd2dA%3D%3D">
              <i className="fa-brands fa-instagram"></i>
            </Link>
            <Link to="https://x.com/lecaracolilb?s=21&t=XslsLTvUKnl1RC2EdKIPdg">
              <i className="fa-brands fa-x"></i>
            </Link>
          </div>
        </section>
      </main>
    </div>
  );
}

export default ContactUs;
