import "../../assets/Styles/Categories.css";
import ProductList from "../../components/ProductList";
import { useTranslation } from "react-i18next";
import useLanguage from "../../hooks/useLanguage";

function DehydratedSkin() {
  const { t } = useTranslation();
  useLanguage();

  return (
    <>
      <div id="DehydratedSkinBanner" className="banner banner-cover">
        <h1>{t("Needs.dehydratedSkinPage.banner")}</h1>
      </div>
      <div>
        <h3 className="heading1">{t("Needs.dehydratedSkinPage.heading1")}</h3>
        <h4 className="heading2">{t("Needs.dehydratedSkinPage.paragraph1")}</h4>
        <p className="paragraph">{t("Needs.dehydratedSkinPage.paragraph2")}</p>
      </div>
      <ProductList categoryName={"Dehydrated skin"} />
    </>
  );
}

export default DehydratedSkin;
