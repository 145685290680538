import "./App.css";
import Home from "./pages/Home";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Menu from "./components/Menu";
import Footer from "./components/Footer";
import EspressoRange from "./pages/Ranges/EspressoRange";
import MokaRange from "./pages/Ranges/MokaRange";
import { Routes, Route, useLocation } from "react-router-dom";
import MacchiatoRange from "./pages/Ranges/MacchiatoRange";
import CappuccinoRange from "./pages/Ranges/CappuccinoRange";
import MakeUpRemoversCleansers from "./pages/Categories/MakeUpRemoversCleansers";
import ExfoliatorsScrubs from "./pages/Categories/ExfoliatorsScrubs";
import Masks from "./pages/Categories/Masks";
import Serums from "./pages/Categories/Serums";
import EyeLip from "./pages/Categories/EyeLip";
import BodyCare from "./pages/Categories/BodyCare";
import FaceCreams from "./pages/Categories/FaceCreams";
import Lightening from "./pages/Categories/Lightening";
import BackToTopButton from "./components/BackToTop";
import DuoTrio from "./pages/Boxes/DueTrio";
import Boxes from "./pages/Boxes/Boxes";
import AntiAging from "./pages/Need/AntiAging";
import PimplesBlemishes from "./pages/Need/PimplesBlemishes";
import DarkCircles from "./pages/Need/DarkCircles";
import Nourishment from "./pages/Need/Nourishment";
import OilySkin from "./pages/Need/OilySkin";
import DehydratedSkin from "./pages/Need/DehydratedSkin";
import NormalCombinationSkin from "./pages/Need/NormalCombinationSkin";
import Redness from "./pages/Need/Redness";
import Pigmentations from "./pages/Need/Pigmentations";
import DullComplexion from "./pages/Need/DullComplexion";
import StretchMarks from "./pages/Need/StretchMarks";
import BrandUniverse from "./pages/BrandUniverse";
import SpecialOffers from "./pages/SpecialOffers";
import LaCafeotherapie from "./pages/LaCafeotherapie";
import SecretBeauty from "./pages/SecretBeauty";
import ProductPage from "./pages/ProductPage";
import OnlineShop from "./pages/OnlineShop";
import ContactUs from "./pages/ContactUs";
import Payment from "./pages/Payment";
import TermsOfSales from "./pages/TermsOfSales";
import CartButton from "./components/CartButton";
import CartPage from "./pages/Cart";

function App() {
  const location = useLocation();

  return (
    <>
      <Menu />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/Home" element={<Home />} />
        {/* these are the Ranges */}
        <Route path="/espresso-range" element={<EspressoRange />} />
        <Route path="/moka-range" element={<MokaRange />} />
        <Route path="/macchiato-range" element={<MacchiatoRange />} />
        <Route path="/cappuccino-range" element={<CappuccinoRange />} />
        {/* these are the Categories */}
        <Route
          path="/make-up-removers-cleansers"
          element={<MakeUpRemoversCleansers />}
        />
        <Route path="/exfoliators-scrubs" element={<ExfoliatorsScrubs />} />
        <Route path="/masks" element={<Masks />} />
        <Route path="/serums" element={<Serums />} />
        <Route path="/eye-lip-contour-care" element={<EyeLip />} />
        <Route path="/body-care" element={<BodyCare />} />
        <Route
          path="/lightening-anti-dark-spot-care"
          element={<Lightening />}
        />
        <Route path="/face-creams" element={<FaceCreams />} />
        {/* these are the Needs */}
        <Route path="/anti-aging-loss-of-firmness" element={<AntiAging />} />
        <Route path="/pimples-blemishes" element={<PimplesBlemishes />} />
        <Route path="/dark-circles-puffiness" element={<DarkCircles />} />
        <Route path="/nourishment-protection" element={<Nourishment />} />
        <Route path="/oily-skin" element={<OilySkin />} />
        <Route path="/dehydrated-skin" element={<DehydratedSkin />} />
        <Route
          path="/normal-combination-skin"
          element={<NormalCombinationSkin />}
        />
        <Route path="/redness" element={<Redness />} />
        <Route path="/eye-lip-contour-care" element={<EyeLip />} />
        <Route path="/body-care" element={<BodyCare />} />
        <Route
          path="/pigmentation-spots-uniformity-of-the-complexion"
          element={<Pigmentations />}
        />
        <Route path="/dull-complexion" element={<DullComplexion />} />
        <Route path="/stretch-marks-cellulite" element={<StretchMarks />} />
        {/* these are the Boxes */}
        <Route path="/boxes" element={<Boxes />} />
        <Route path="/duo-trio" element={<DuoTrio />} />
        {/*  */}
        <Route path="/la-cafeotherapie" element={<LaCafeotherapie />} />
        {/*  */}
        <Route path="/lunivers-de-la-marque" element={<BrandUniverse />} />
        {/*  */}
        <Route
          path="/offres-speciales-produits-de-beaute"
          element={<SpecialOffers />}
        />
        {/*  */}
        <Route path="/beaute-secrete" element={<SecretBeauty />} />
        <Route path="/product/:id" element={<ProductPage />} />
        <Route path="/OnlineShop" element={<OnlineShop />} />
        <Route path="/ContactUs" element={<ContactUs />} />
        <Route path="/payment" element={<Payment />} />
        <Route path="/termsofsales" element={<TermsOfSales />} />
        <Route path="/cart" element={<CartPage />} />
      </Routes>
      <BackToTopButton />
      {(location.pathname !== "/Cart" && location.pathname !== '/cart') && <CartButton />}
      <Footer />
    </>
  );
}

export default App;
