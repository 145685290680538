import "../../assets/Styles/Categories.css";
import ProductList from "../../components/ProductList";
import { useTranslation } from "react-i18next";
import useLanguage from "../../hooks/useLanguage";

function MakeUpRemoversCleansers() {
  const { t } = useTranslation();
  useLanguage();

  return (
    <>
      <div id="MakeUpBanner" className="banner banner-contain">
        <h1>{t("Categories.makeUpRemoversCleansersPage.banner")}</h1>
      </div>
      <div>
        <h3 className="heading1">
          {t("Categories.makeUpRemoversCleansersPage.heading1")}
        </h3>
        <h4 className="headin2">
          {t("Categories.makeUpRemoversCleansersPage.paragraph1")}
        </h4>
        <p className="paragraph">
          {t("Categories.makeUpRemoversCleansersPage.paragraph2")}
        </p>
      </div>
      <ProductList categoryName={"Make-up removers & cleansers"} />
    </>
  );
}

export default MakeUpRemoversCleansers;
