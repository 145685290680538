import { useState, useEffect } from "react";

const Cart = () => {
  const [cartItems, setCartItems] = useState(0);

  const updateCartItems = () => {
    const cart = JSON.parse(sessionStorage.getItem("cart") || "[]");
    const totalQuantity = cart.reduce((sum, item) => sum + item.qty, 0);
    setCartItems(totalQuantity);
  };

  useEffect(() => {
    updateCartItems();

    const handleCartUpdated = () => {
      updateCartItems();
    };

    window.addEventListener("cartUpdated", handleCartUpdated);

    return () => {
      window.removeEventListener("cartUpdated", handleCartUpdated);
    };
  }, []);

  if (cartItems === 0) {
    return null; // Do not render the button if cartItems is zero
  }
  return (
    <a href="/Cart">
      <button
        id="backToTopBtn"
        style={{
          position: "fixed",
          bottom: "20px",
          left: "30px",
          zIndex: "99",
          border: "none",
          outline: "none",
          backgroundColor: "#1346af",
          color: "white",
          cursor: "pointer",
          padding: "10px 10px",
          borderRadius: "30px",
          fontSize: "18px",
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="lucide lucide-shopping-cart"
        >
          <circle cx="8" cy="21" r="1" />
          <circle cx="19" cy="21" r="1" />
          <path d="M2.05 2.05h2l2.66 12.42a2 2 0 0 0 2 1.58h9.78a2 2 0 0 0 1.95-1.57l1.65-7.43H5.12" />
        </svg>
        {cartItems > 0 && (
          <span
            style={{
              position: "absolute",
              top: "-10px",
              right: "-10px",
              backgroundColor: "red",
              color: "white",
              borderRadius: "50%",
              padding: "5px 10px",
              fontSize: "14px",
              fontWeight: "bold",
            }}
          >
            {cartItems}
          </span>
        )}
      </button>
    </a>
  );
};

export default Cart;
