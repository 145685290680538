import "../../assets/Styles/Categories.css";
import ProductList from "../../components/ProductList";
import { useTranslation } from "react-i18next";
import useLanguage from "../../hooks/useLanguage";

function Redness() {
  const { t } = useTranslation();
  useLanguage();

  return (
    <>
      <div id="RednessBanner" className="banner banner-cover">
        <h1>{t("Needs.rednessPage.banner")}</h1>
      </div>
      <div>
        <h3 className="heading1">{t("Needs.rednessPage.heading1")}</h3>
        <h4 className="heading2">{t("Needs.rednessPage.paragraph1")}</h4>
        <p className="paragraph">{t("Needs.rednessPage.paragraph2")}</p>
      </div>
      <ProductList categoryName={"Redness"} />
    </>
  );
}

export default Redness;
