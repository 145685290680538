import "../../assets/Styles/Categories.css";
import ProductList from "../../components/ProductList";
import { useTranslation } from "react-i18next";
import useLanguage from "../../hooks/useLanguage";

function NormalCombinationSkin() {
  const { t } = useTranslation();
  useLanguage();

  return (
    <>
      <div id="NormalCombinationSkinBanner" className="banner banner-cover">
        <h1>{t("Needs.normalCombinationSkinPage.banner")}</h1>
      </div>
      <div>
        <h3 className="heading1">
          {t("Needs.normalCombinationSkinPage.heading1")}
        </h3>
        <h4 className="heading2">
          {t("Needs.normalCombinationSkinPage.heading2")}
        </h4>
        <p className="paragraph">
          {t("Needs.normalCombinationSkinPage.paragraph1")}
        </p>
      </div>
      <ProductList categoryName={"Normal & combination skin"} />
    </>
  );
}

export default NormalCombinationSkin;
