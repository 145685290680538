/* eslint-disable react/prop-types */
import "../assets/Styles/ProductCard.css";
import { Link } from "react-router-dom";

const ProductCard = ({ product }) => {
  const handleClick = () => {
    window.scrollTo(0, 0);
  };
  return (
    <div className="col-md-4">
      <Link to={`/product/${product.ID}`} onClick={handleClick}>
        <div className="card product-card">
          <img
            src={product.Images[0]}
            className="card-img-top"
            alt={product.Name}
          />
          <div className="card-body text-center">
            <h6 className="card-title">{product.Name}</h6>
            {/* <p className="card-text">{product["Short description"]}</p>
          <div className="d-flex justify-content-center align-items-center mb-2">
          <button className="btn btn-outline-secondary">-</button>
          <span className="mx-2">1</span>
          <button className="btn btn-outline-secondary">+</button>
          </div> */}
            <p className="price">${product["Price"]}</p>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default ProductCard;
