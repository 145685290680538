import "../../assets/Styles/Categories.css";
import ProductList from "../../components/ProductList";
import { useTranslation } from "react-i18next";
import useLanguage from "../../hooks/useLanguage";

function ExfoliatorsScrubs() {
  const { t } = useTranslation();
  useLanguage();

  return (
    <>
      <div id="ExfoliatorBanner" className="banner banner-contain">
        <h1>{t("Categories.exfoliatorsScrubsPage.banner")}</h1>
      </div>
      <div>
        <p className="paragraph">
          {t("Categories.exfoliatorsScrubsPage.paragraph")}
        </p>
      </div>
      <ProductList categoryName={"Exfoliators & scrubs"} />
    </>
  );
}

export default ExfoliatorsScrubs;
