/* eslint-disable react/prop-types */
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import ProductCard from "./ProductCard";
import products from "../products.json";

const ProductList = ({ categoryName }) => {
  const { i18n } = useTranslation();
  const [filteredProducts, setFilteredProducts] = useState([]);

  useEffect(() => {
    const savedLanguage = sessionStorage.getItem("lng") || i18n.language;
    let productList = [];

    if (savedLanguage === "fr") {
      productList = products[1];
    } else {
      productList = products[0];
    }

    if (categoryName === "*") {
      setFilteredProducts(productList);
    } else {
      setFilteredProducts(
        productList.filter((product) =>
          product.Categories.includes(categoryName)
        )
      );
    }
  }, [i18n.language, categoryName]);

  return (
    <div className="container">
      <div className="row">
        {filteredProducts.map((product, index) => (
          <ProductCard key={index} product={product} />
        ))}
      </div>
    </div>
  );
};

export default ProductList;
