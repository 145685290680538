import "../../assets/Styles/Ranges/macchiatoRange.css";
import gift1 from "../../assets/imgs/Ranges/macchiatoRange/gift1.jpg";
import gift2 from "../../assets/imgs/Ranges/macchiatoRange/gift2.jpg";
import ProductList from "../../components/ProductList";
import { useTranslation } from "react-i18next";
import useLanguage from "../../hooks/useLanguage";

function MacchiatoRange() {
  const { t } = useTranslation();
  useLanguage();

  return (
    <>
      <div className="macchiatoRangeBanner"></div>
      <div>
        <h1>{t("macchiatoRangePage.heading1")}</h1>
        <p style={{ textAlign: "center" }}>
          {t("macchiatoRangePage.paragraph1")}
        </p>
        <p style={{ textAlign: "center" }}>
          {t("macchiatoRangePage.paragraph2")}
        </p>
      </div>
      <div className="center">
        <div id="section1">
          <div className="section1Text">
            <h3>{t("macchiatoRangePage.section1.heading1")}</h3>
            <p>{t("macchiatoRangePage.section1.text1")}</p>
          </div>
          <div className="section1Text">
            <h3>{t("macchiatoRangePage.section1.heading2")}</h3>
            <p>{t("macchiatoRangePage.section1.text2")}</p>
          </div>
        </div>
      </div>

      <div>
        <h1 style={{ marginTop: "3%" }}>{t("macchiatoRangePage.heading2")}</h1>
        <p style={{ textAlign: "center" }}>
          {t("macchiatoRangePage.paragraph3")}
        </p>
        <p style={{ textAlign: "center" }}>
          {t("macchiatoRangePage.paragraph4")}
        </p>
      </div>

      <div id="macchiatoRangeBanner2">
        <h1>{t("macchiatoRangePage.banner2.title")}</h1>
        <br />
        <div className="center macchiatoBanner2">
          <div className="centerColumn3">
            <p>{t("macchiatoRangePage.banner2.serum")}</p>
            <a href="/product/5631">
              <button>{t("macchiatoRangePage.banner2.order")}</button>
            </a>
          </div>
          <div className="centerColumn3">
            <p>{t("macchiatoRangePage.banner2.mocha")}</p>
            <a href="/product/5632">
              <button>{t("macchiatoRangePage.banner2.order")}</button>
            </a>
          </div>
        </div>
      </div>

      <div>
        <h1>{t("macchiatoRangePage.heading3")}</h1>
        <h4>{t("macchiatoRangePage.paragraph5")}</h4>

        <div className="center gap">
          <img src={gift1} alt="" width={450} />
          <div className="centerColumn2">
            <p>{t("macchiatoRangePage.gift1.new")}</p>
            <h3>{t("macchiatoRangePage.gift1.title")}</h3>
            <p>{t("macchiatoRangePage.gift1.description")}</p>
            <a href="/product/5626">{t("macchiatoRangePage.gift1.buy")}</a>
          </div>
        </div>
        <br />
        <div className="center gap">
          <div className="centerColumn2">
            <p>{t("macchiatoRangePage.gift2.new")}</p>
            <h3>{t("macchiatoRangePage.gift2.title")}</h3>
            <p>{t("macchiatoRangePage.gift2.description")}</p>
            <a href="/product/5628">{t("macchiatoRangePage.gift2.buy")}</a>
          </div>
          <img src={gift2} alt="" width={450} />
        </div>
      </div>
      <div>
        <h1>{t("macchiatoRangePage.heading4")}</h1>
        <h3>{t("macchiatoRangePage.subheading1")}</h3>
      </div>
      <div className="macchiatoRangeBanner3">
        {t("macchiatoRangePage.banner3")}
      </div>
      <div>
        <h4
          className="center-text"
          style={{ fontSize: "25px", fontWeight: "600", marginTop: "3%" }}
        >
          {t("macchiatoRangePage.closingText1")}
        </h4>
        <h4
          className="center-text"
          style={{ fontSize: "25px", marginBottom: "3%" }}
        >
          {t("macchiatoRangePage.closingText2")}
        </h4>
      </div>
      <ProductList categoryName={"Macchiato range"} />
    </>
  );
}

export default MacchiatoRange;
