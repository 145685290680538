import "../assets/Styles/SecretBeauty.css";
import img2 from "../assets/imgs/SecretBeauty/img2.jpg";
import img3 from "../assets/imgs/SecretBeauty/img3.jpg";
import img4 from "../assets/imgs/SecretBeauty/img4.jpg";
import img5 from "../assets/imgs/SecretBeauty/img5.jpg";
import img6 from "../assets/imgs/SecretBeauty/img7.jpg";
import img7 from "../assets/imgs/SecretBeauty/img8.jpg";
import { useTranslation } from "react-i18next";
import useLanguage from "../hooks/useLanguage";

function SecretBeauty() {
  const { t } = useTranslation();
  useLanguage();

  return (
    <>
      <div id="secretBeautyBanner1">
        <h1>{t("secretBeauty.banner1.title")}</h1>
        <h3>{t("secretBeauty.banner1.subtitle")}</h3>
      </div>
      <div id="secretBeautySection1">
        <div id="img1">
          <h5>{t("secretBeauty.section1.img1.text")}</h5>
        </div>
        <div id="img2Section">
          <div className="img2SectionImgs">
            <div>
              <img src={img2} alt="" />
            </div>
            <div>
              <img src={img3} alt="" />
            </div>
          </div>
          <div className="img2SectionImgs">
            <div>
              <img src={img4} alt="" />
            </div>
            <div>
              <img src={img5} alt="" />
            </div>
          </div>
        </div>
      </div>
      <div id="secretBeautyBanner2">
        <h1>{t("secretBeauty.banner2.title")}</h1>
        <h5>{t("secretBeauty.banner2.subtitle")}</h5>
      </div>
      <div id="secretBeautySection2" className="container">
        <div id="img2Section" className="row">
          <div className="column">
            <div className="img2SectionImgs">
              <div>
                <img src={img6} alt="" />
              </div>
            </div>
            <div className="img2SectionImgs">
              <div>
                <img src={img7} alt="" />
              </div>
            </div>
          </div>
          <div className="column">
            <div id="img6">
              <h5>{t("secretBeauty.section2.img6.text")}</h5>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SecretBeauty;
