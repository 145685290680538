import React from "react";
import { useTranslation } from "react-i18next";

const TermsOfSales = () => {
  const { t } = useTranslation();
  const renderDescription = () => {
    return { __html: t("termOfSale.part1") + t('termOfSale.part2') };
  };
  return (
    <div className="termOfSales"
      style={{ margin: "3%", lineHeight: "200%" }}
      dangerouslySetInnerHTML={renderDescription()}
    ></div>
  );
};

export default TermsOfSales;
