import "../../assets/Styles/Categories.css";
import ProductList from "../../components/ProductList";
import { useTranslation } from "react-i18next";
import useLanguage from "../../hooks/useLanguage";

function Lightening() {
  const { t } = useTranslation();
  useLanguage();

  return (
    <>
      <div id="lighteningBanner" className="banner banner-cover">
        <h1>{t("Categories.lighteningPage.banner")}</h1>
      </div>
      <div>
        <h3 className="heading1">{t("Categories.lighteningPage.heading1")}</h3>
        <h4 className="heading2">
          {t("Categories.lighteningPage.paragraph1")}
        </h4>
        <h5 className="paragraph">
          {t("Categories.lighteningPage.paragraph2")}
        </h5>
      </div>
      <ProductList categoryName={"Lightening & anti-dark spot care"} />
    </>
  );
}

export default Lightening;
