import ProductList from "../../components/ProductList";
import { useTranslation } from "react-i18next";
import useLanguage from "../../hooks/useLanguage";
import "../../assets/Styles/Categories.css";

function AntiAging() {
  const { t } = useTranslation();
  useLanguage();

  return (
    <>
      <div id="AntiAgingBanner" className="banner banner-cover">
        <h1>{t("Needs.antiAgingPage.banner")}</h1>
      </div>
      <div>
        <h3 className="heading1">{t("Needs.antiAgingPage.heading1")}</h3>
        <h4 className="heading2">{t("Needs.antiAgingPage.heading2")}</h4>
        <p className="paragraph">{t("Needs.antiAgingPage.paragraph1")}</p>
      </div>
      <ProductList categoryName={"Anti-aging & Loss of firmness"} />
    </>
  );
}

export default AntiAging;
