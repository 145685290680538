import { useState, useEffect } from "react";

const BackToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    const toggleVisibility = () => {
      if (window.scrollY > 300) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", toggleVisibility);

    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <button
      id="backToTopBtn"
      onClick={scrollToTop}
      style={{
        display: isVisible ? "block" : "none",
        position: "fixed",
        bottom: "20px",
        right: " 30px",
        zIndex: "99",
        border: "none",
        outline: "none",
        backgroundColor: "#1346af",
        color: "white",
        cursor: "pointer",
        padding: "10px 18px",
        borderRadius: "10px",
        fontSize: "18px",
      }}
    >
      ^
    </button>
  );
};

export default BackToTopButton;
