import "../../assets/Styles/Categories.css";
import ProductList from "../../components/ProductList";
import { useTranslation } from "react-i18next";
import useLanguage from "../../hooks/useLanguage";

function Masks() {
  const { t } = useTranslation();
  useLanguage();

  return (
    <>
      <div id="MasksBanner" className="banner banner-contain">
        <h1>{t("Categories.masksPage.banner")}</h1>
      </div>
      <div>
        <h4 className="heading2">{t("Categories.masksPage.heading1")}</h4>
        <p className="paragraph">{t("Categories.masksPage.paragraph1")}</p>
      </div>
      <ProductList categoryName={"Masks"} />
    </>
  );
}

export default Masks;
