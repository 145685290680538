import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import productsData from "../products.json";
import "../assets/Styles/ProductPage.css";
import ProductList from "../components/ProductList";
import { useTranslation } from "react-i18next";
import pay from "../assets/imgs/cod.png";
import Carousel from "react-bootstrap/Carousel";

function ProductPage() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [product, setProduct] = useState(null);
  const [activeTab, setActiveTab] = useState("description");
  const { t, i18n } = useTranslation();
  const [quantity, setQuantity] = useState(1);

  useEffect(() => {
    const fetchProduct = () => {
      let selectedProduct = productsData[0].find(
        (product) => product.ID === id
      );
      if (i18n.language === "fr") {
        selectedProduct = productsData[1].find((product) => product.ID === id);
      } else {
        selectedProduct = productsData[0].find((product) => product.ID === id);
      }

      if (selectedProduct) {
        setProduct(selectedProduct);
      } else {
        navigate("/");
      }
    };

    fetchProduct(); // Initial fetch when component mounts

    const languageChangeListener = () => {
      fetchProduct(); // Fetch product when language changes
    };

    // Add event listener for language change
    window.addEventListener("languageChange", languageChangeListener);

    return () => {
      window.removeEventListener("languageChange", languageChangeListener);
    };
  }, [id, i18n.language, navigate]); // Re-run when language changes

  const renderDescription = (description) => {
    const formattedDescription = description
      .replace(/\r/g, "") // Remove \r
      .replace(/\\n/g, "<br>") // Replace \\n with <br>
      .replace(/&nbsp;/g, " "); // Replace &nbsp; with space
    return { __html: formattedDescription };
  };

  const Description = () => (
    <div>
      <br />
      <p dangerouslySetInnerHTML={renderDescription(product.Description)}></p>
      <hr style={{ width: "80%" }} />
    </div>
  );

  const ExceptionalBlend = () => (
    <div>
      {/* <h2>{t("productpage.keyIngredients")}</h2> */}
      <p dangerouslySetInnerHTML={renderDescription(product.Blend)}></p>
    </div>
  );

  const Composition = () => (
    <div>
      {/* <h2>{t("productpage.composition")}</h2> */}
      <p dangerouslySetInnerHTML={renderDescription(product.Composition)}></p>
    </div>
  );

  const UsageTips = () => (
    <div>
      {/* <h2>{t("productpage.usageTips")}</h2> */}
      <p dangerouslySetInnerHTML={renderDescription(product.Usage)}></p>
    </div>
  );

  const DeliveryReturns = () => (
    <div>
      {/* <p>
        <b>{t("productpage.shippingFeesTitle")}</b>
        <br />
        {t("productpage.shippingFees")}
      </p> */}

      <p>
        <b>{t("productpage.deliveryLocationsTitle")}</b>
        <br />
        {t("productpage.deliveryLocations")}
      </p>

      <p>
        <b>{t("productpage.returnPolicyTitle")}</b>
        <br />
        {t("productpage.returnPolicy")}
      </p>

      <p>
        <b>{t("productpage.deliveryTimesTitle")}</b>
        <br />
        {t("productpage.deliveryTimes")}
      </p>
    </div>
  );

  const renderContent = () => {
    switch (activeTab) {
      case "description":
        return <Description />;
      case "exceptionalBlend":
        return <ExceptionalBlend />;
      case "composition":
        return <Composition />;
      case "usageTips":
        return <UsageTips />;
      case "deliveryReturns":
        return <DeliveryReturns />;
      default:
        return <Description />;
    }
  };

  const handleAddToCart = () => {
    const cart = JSON.parse(sessionStorage.getItem("cart")) || [];
    const productInCart = cart.find((item) => item.productId === id);
    if (productInCart) {
      productInCart.qty += quantity;
    } else {
      cart.push({ productId: id, qty: quantity });
    }
    sessionStorage.setItem("cart", JSON.stringify(cart));

    const event = new Event("cartUpdated");
    window.dispatchEvent(event);
  };

  const handleQuantityChange = (amount) => {
    setQuantity((prevQuantity) => Math.max(1, prevQuantity + amount));
  };

  return (
    <div>
      {product ? (
        <>
          <div id="productSection1">
            <div className="carousel-container">
              {product.Images.length > 1 ? (
                <Carousel>
                  {product.Images.map((image, index) => (
                    <Carousel.Item key={index}>
                      <img
                        className="d-block w-100 carousel-image"
                        src={image}
                        alt={`Product ${index}`}
                      />
                    </Carousel.Item>
                  ))}
                </Carousel>
              ) : (
                <img
                  className="d-block w-100 carousel-image"
                  src={product.Images[0]}
                  alt="Product"
                />
              )}
            </div>
            <div>
              <h2 style={{ textAlign: "start" }}>{product.Name}</h2>
              <h3 style={{ textAlign: "start" }}>${product.Price}</h3>
              <br />
              <h4
                style={{ textAlign: "start" }}
                dangerouslySetInnerHTML={renderDescription(
                  product.ShortDescription
                )}
              ></h4>
              <br />
              <div style={{ display: "flex", alignItems: "center" }}>
                <button
                  className="itemButtonsinProducts"
                  onClick={() => handleQuantityChange(-1)}
                >
                  -
                </button>
                <input
                  className="itemsInputinProducts"
                  type="number"
                  readOnly
                  value={quantity}
                  onChange={(e) =>
                    setQuantity(Math.max(1, Number(e.target.value)))
                  }
                  style={{ width: "50px", textAlign: "center" }}
                />
                <button
                  className="itemButtonsinProducts"
                  onClick={() => handleQuantityChange(1)}
                >
                  +
                </button>
              </div>
              <br />
              <button
                className="itemButtonsinProducts"
                onClick={handleAddToCart}
              >
                {t("productpage.addToCart")}
              </button>
              <br />
              <br />
              <div style={{ alignItems: "center" }}>
                <h5 style={{ textAlign: "start", margin: 0 }}>
                  {t("productpage.payWith")}
                </h5>
                <img
                  src={pay}
                  alt="omt/whish"
                  style={{
                    marginTop: "8px",
                    maxWidth: "100%",
                    height: "auto",
                    width: "150px",
                  }}
                />
              </div>

              <br />
              <h5 style={{ textAlign: "start" }}>
                {t("productpage.categories")}: {product.Categories}
              </h5>
            </div>
          </div>
          <br />
          <br />
          {product.single !== "" && (
            <div className="tab-container">
              <div className="menu">
                <button
                  onClick={() => setActiveTab("description")}
                  style={{ backgroundColor: "whitesmoke", color: "black" }}
                >
                  {t("productpage.description")}
                </button>
                {product.Blend !== "" && (
                  <button
                    onClick={() => setActiveTab("exceptionalBlend")}
                    style={{ backgroundColor: "whitesmoke", color: "black" }}
                  >
                    {t("productpage.exceptionalBlend")}
                  </button>
                )}
                {product.Composition !== "" && (
                  <button
                    onClick={() => setActiveTab("composition")}
                    style={{ backgroundColor: "whitesmoke", color: "black" }}
                  >
                    {t("productpage.composition")}
                  </button>
                )}
                {product.Usage !== "" && (
                  <button
                    onClick={() => setActiveTab("usageTips")}
                    style={{ backgroundColor: "whitesmoke", color: "black" }}
                  >
                    {t("productpage.usageTips")}
                  </button>
                )}
                <button
                  onClick={() => setActiveTab("deliveryReturns")}
                  style={{ backgroundColor: "whitesmoke", color: "black" }}
                >
                  {t("productpage.deliveryReturns")}
                </button>
              </div>
              <div className="content">{renderContent()}</div>
            </div>
          )}
          <div>
            <br />
            <h5>{t("productpage.relatedProducts")}</h5>
            <br />
            <ProductList categoryName={"Espresso range"} />
          </div>
        </>
      ) : (
        <p>{t("productpage.productNotFound")}</p>
      )}
    </div>
  );
}
export default ProductPage;
