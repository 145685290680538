import "../assets/Styles/BrandUniverse.css";
import { useTranslation } from "react-i18next";
import useLanguage from "../hooks/useLanguage";

function BrandUniverse() {
  const { t } = useTranslation();
  useLanguage();

  return (
    <>
      <div id="ourHistory">
        <h1>{t("BrandUniverse.ourHistory.title")}</h1>
        <div>
          <p>
            <b>{t("BrandUniverse.ourHistory.boldText")}</b>
            <br />
            {t("BrandUniverse.ourHistory.paragraph")}
          </p>
        </div>
      </div>
      <div id="Simplicity">
        <h1>{t("BrandUniverse.simplicity.title")}</h1>
        <div>
          <p>{t("BrandUniverse.simplicity.paragraph")}</p>
        </div>
      </div>
      <div id="ourHistory">
        <h1>{t("BrandUniverse.keyIngredient.title")}</h1>
        <div>
          <p>{t("BrandUniverse.keyIngredient.paragraph")}</p>
        </div>
      </div>
      <div id="TheCoffeeBean">
        <h1>{t("BrandUniverse.coffeeBean.title")}</h1>
        <div>
          <p>{t("BrandUniverse.coffeeBean.paragraph1")}</p>
          <p>{t("BrandUniverse.coffeeBean.paragraph2")}</p>
          <ul>
            {t("BrandUniverse.coffeeBean.ulItems", { returnObjects: true }).map(
              (item, index) => (
                <li key={index}>
                  <b>{item.boldText}</b>: {item.text}
                </li>
              )
            )}
          </ul>
        </div>
      </div>
      <div id="research">
        <h1>{t("BrandUniverse.research.title")}</h1>
        <div>
          <p>{t("BrandUniverse.research.paragraph1")}</p>
          <p>{t("BrandUniverse.research.paragraph2")}</p>
          <strong>{t("BrandUniverse.research.strongText")}</strong>
          {t("BrandUniverse.research.finalParagraph")}
        </div>
      </div>
      <div id="caffoetherapy">
        <h1>{t("BrandUniverse.caffeotherapy.title")}</h1>
        <div>
          <p>{t("BrandUniverse.caffeotherapy.paragraph")}</p>
        </div>
      </div>
      <div id="friendly">
        <h1>{t("BrandUniverse.ecoFriendly.title")}</h1>
        <div>
          <p>{t("BrandUniverse.ecoFriendly.paragraph")}</p>
        </div>
      </div>
      <div id="package">
        <h1>{t("BrandUniverse.packaging.title")}</h1>
        <div>
          <p>{t("BrandUniverse.packaging.paragraph")}</p>
        </div>
      </div>
      <div id="values">
        <h1>{t("BrandUniverse.values.title")}</h1>
        <div>
          <p>
            <b>{t("BrandUniverse.values.respectBold")}</b>
            {t("BrandUniverse.values.respectParagraph")}
          </p>
          <p>
            <b>{t("BrandUniverse.values.bioNaturalBold")}</b>
            {t("BrandUniverse.values.bioNaturalParagraph")}
          </p>
          <p>
            <b>{t("BrandUniverse.values.innovationBold")}</b>
            {t("BrandUniverse.values.innovationParagraph")}
          </p>
          <p>
            <b>{t("BrandUniverse.values.effectivenessBold")}</b>
            {t("BrandUniverse.values.effectivenessParagraph")}
          </p>
        </div>
      </div>
      <div id="ingredients">
        <h1>{t("BrandUniverse.ingredients.title")}</h1>
        <div>
          <p>
            <b>{t("BrandUniverse.ingredients.organicBold")}</b>
            <br />
            {t("BrandUniverse.ingredients.paragraph")}
          </p>
        </div>
      </div>
      <div id="products">
        <h1>{t("BrandUniverse.products.title")}</h1>
        <div>
          <p>
            <b>{t("BrandUniverse.products.certificationBold")}</b>
            {t("BrandUniverse.products.paragraph")}
          </p>
        </div>
      </div>
    </>
  );
}

export default BrandUniverse;
