import "../../assets/Styles/Categories.css";
import ProductList from "../../components/ProductList";
import { useTranslation } from "react-i18next";
import useLanguage from "../../hooks/useLanguage";

function Pigmentations() {
  const { t } = useTranslation();
  useLanguage();

  return (
    <>
      <div id="PigmentationsBanner" className="banner banner-cover">
        <h1>{t("Needs.pigmentationsPage.banner")}</h1>
      </div>
      <div>
        <h3 className="heading1">{t("Needs.pigmentationsPage.heading1")}</h3>
        <h4 className="heading2">{t("Needs.pigmentationsPage.heading2")}</h4>
        <p className="paragraph">{t("Needs.pigmentationsPage.paragraph1")}</p>
      </div>
      <ProductList
        categoryName={"Pigmentation spots & uniformity of the complexion"}
      />
    </>
  );
}

export default Pigmentations;
