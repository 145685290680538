import { useEffect } from "react";
import { useTranslation } from "react-i18next";

function useLanguage() {
  const { i18n } = useTranslation();

  useEffect(() => {
    const savedLanguage = sessionStorage.getItem("lng");
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }
  }, [i18n]);

  useEffect(() => {
    const languageChangeListener = () => {
      const savedLanguage = sessionStorage.getItem("lng");
      if (savedLanguage) {
        i18n.changeLanguage(savedLanguage);
      }
    };

    window.addEventListener("languageChange", languageChangeListener);

    return () => {
      window.removeEventListener("languageChange", languageChangeListener);
    };
  }, [i18n]);
}

export default useLanguage;
