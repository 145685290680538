import { useTranslation } from "react-i18next";
import discoveryBox from "../../assets/imgs/Ranges/espressoRange/discovery-box-intensive-hydration.png";
import "../../assets/Styles/Ranges/espressoRange.css";
import ProductList from "../../components/ProductList";
import useLanguage from "../../hooks/useLanguage";

const EspressoRange = () => {
  const { t } = useTranslation();
  useLanguage();

  return (
    <>
      <div id="espressoRangeBanner">
        <h1>{t("espressoRangePage.bannerTitle")}</h1>
        <h4>{t("espressoRangePage.bannerSubtitle")}</h4>
      </div>

      <div>
        <h2 className="center-text margin-3 titleSection">
          {t("espressoRangePage.sectionTitle1")}
        </h2>

        <h5 className="center-text textSection">
          {t("espressoRangePage.sectionText1")}
        </h5>
        <br />
        <h1 className="center-text">{t("espressoRangePage.sectionTitle2")}</h1>
        <h2 className="center-text espressoRangeSection1sub">
          {t("espressoRangePage.sectionTitle3")}
        </h2>
        <br />
        <div className="flex-center">
          <div className="flex-center width-50 gap-10">
            <img src={discoveryBox} alt="" />
            <div className="flex-center flex-column width-40">
              <p className="center-text margin-0">
                {t("espressoRangePage.discoveryBoxTitle")}
              </p>
              <h2 className="center-text textNormal">
                {t("espressoRangePage.discoveryBoxText")}
              </h2>

              <a href="/Product/5699">{t("espressoRangePage.buyLinkText")}</a>
            </div>
          </div>
        </div>
        <div className="flex-center" style={{ marginTop: "5%" }}>
          <div className="flex-center width-50 gap-10">
            <div className="flex-center flex-column width-40">
              <p className="center-text margin-0">
                {t("espressoRangePage.discoveryBoxTitle")}
              </p>
              <h2 className="center-text textNormal">
                {t("espressoRangePage.discoveryBoxText")}
              </h2>
              <a href="/Product/5699">{t("espressoRangePage.buyLinkText")}</a>
            </div>
            <img src={discoveryBox} alt="" />
          </div>
        </div>
      </div>
      <div id="espressoRangeBanner2">
        <div>
          <h1>{t("espressoRangePage.banner2Title")}</h1>
          <h4>{t("espressoRangePage.banner2Subtitle")}</h4>
        </div>
        <br />
      </div>
      <div>
        <h4 className="center-text margin-3-0 " style={{ fontSize: "20px" }}>
          {t("espressoRangePage.sectionText2")}
        </h4>
      </div>
      <h4
        className="center-text espressoRangeBanner3h4"
        style={{ fontSize: "40px" }}
      >
        {t("espressoRangePage.careRoutineTitle")}
        <br />
        {t("espressoRangePage.careRoutineText")}
      </h4>
      <div id="espressoRangeBanner3">
        <h1>{t("espressoRangePage.banner3Title")}</h1>
        <br />
      </div>
      <div>
        <h4
          className="center-text espressoRangeBanner3h4"
          style={{ fontSize: "25px", fontWeight: "600", marginTop: "3%" }}
        >
          {t("espressoRangePage.magnifyBeautyTitle")}
        </h4>
        <h4
          className="center-text"
          style={{ fontSize: "25px", marginBottom: "3%" }}
        >
          {t("espressoRangePage.discoverProductsText")}
        </h4>
      </div>
      <ProductList categoryName={"Espresso range"} />
    </>
  );
};

export default EspressoRange;
