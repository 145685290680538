import { useEffect, useState } from "react";
import "../assets/Styles/menu.css";
import menuLogo from "../assets/imgs/Home/menuLogo.png";
import { useTranslation } from "react-i18next";
// import { Navigate } from "react-router-dom";

function Menu() {
  const { t, i18n } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState("en");
  const [menuOpen, setMenuOpen] = useState(false);
  const [activeMenu, setActiveMenu] = useState(null);
  const [activeSubMenu, setActiveSubMenu] = useState(null);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
    setActiveMenu(null);
  };

  const handleMenuClick = (menuName) => {
    setActiveMenu(activeMenu === menuName ? null : menuName);
    setActiveSubMenu(null);
  };

  const handleSubMenuClick = (subMenuName) => {
    setActiveSubMenu(activeSubMenu === subMenuName ? null : subMenuName);
  };

  useEffect(() => {
    const savedLanguage = sessionStorage.getItem("lng");
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage);
      setSelectedLanguage(savedLanguage); // Update selected language state
    }
  }, [i18n]);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setSelectedLanguage(lng); // Update selected language state
  };

  const handleLanguageChange = (event) => {
    const selectedLanguage = event.target.value;
    sessionStorage.setItem("lng", selectedLanguage);
    changeLanguage(selectedLanguage);
  };
  useEffect(() => {
    const savedLanguage = sessionStorage.getItem("lng");
    if (savedLanguage === "fr") {
      setSelectedLanguage("fr");
    } else {
      setSelectedLanguage("en");
    }
  }, []);

  return (
    <>
      <div id="MobileMenu">
        <p
          className="hamburger-menu"
          style={{ fontSize: "35px" }}
          onClick={toggleMenu}
        >
          &#9776;
        </p>
      </div>
      {menuOpen && (
        <div className="mobileMenuDiv">
          <div className="close-btn" onClick={toggleMenu}>
            &times;
          </div>
          <ul>
            <li>
              <div
                onClick={() => handleMenuClick("products")}
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                {t("Menu.products")}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-chevron-down"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708"
                  />
                </svg>
              </div>
              {activeMenu === "products" && (
                <div className="subsubmenu">
                  <ul>
                    <li onClick={() => handleSubMenuClick("products1")}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        {t("Menu.ranges")}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-chevron-down"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fillRule="evenodd"
                            d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708"
                          />
                        </svg>
                      </div>
                      {activeSubMenu === "products1" && (
                        <ul>
                          <li>
                            <a href="/espresso-range">
                              {t("Menu.espressoRange")}
                            </a>
                          </li>
                          <li>
                            <a href="/moka-range">{t("Menu.mokaRange")}</a>
                          </li>
                          <li>
                            <a href="/macchiato-range">
                              {t("Menu.macchiatoRange")}
                            </a>
                          </li>
                          <li>
                            <a href="/cappuccino-range">
                              {t("Menu.cappuccinoRange")}
                            </a>
                          </li>
                        </ul>
                      )}
                    </li>
                    <li onClick={() => handleSubMenuClick("products2")}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        {t("Menu.category")}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-chevron-down"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fillRule="evenodd"
                            d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708"
                          />
                        </svg>
                      </div>
                      {activeSubMenu === "products2" && (
                        <ul>
                          <li>
                            <a href="make-up-removers-cleansers">
                              {t("Menu.makeUpRemoversCleansers")}
                            </a>
                          </li>
                          <li>
                            <a href="/exfoliators-scrubs">
                              {t("Menu.exfoliatorsScrubs")}
                            </a>
                          </li>
                          <li>
                            <a href="/masks">{t("Menu.masks")}</a>
                          </li>
                          <li>
                            <a href="/serums">{t("Menu.serums")}</a>
                          </li>
                          <li>
                            <a href="/eye-lip-contour-care">
                              {t("Menu.eyeLipContourCare")}
                            </a>
                          </li>
                          <li>
                            <a href="/body-care">{t("Menu.bodyCare")}</a>
                          </li>
                          <li>
                            <a href="/lightening-anti-dark-spot-care">
                              {t("Menu.lighteningAntiDarkSpotCare")}
                            </a>
                          </li>
                          <li>
                            <a href="/face-creams">{t("Menu.faceCreams")}</a>
                          </li>
                        </ul>
                      )}
                    </li>
                    <li onClick={() => handleSubMenuClick("products3")}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        {t("Menu.need")}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-chevron-down"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fillRule="evenodd"
                            d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708"
                          />
                        </svg>
                      </div>
                      {activeSubMenu === "products3" && (
                        <ul>
                          <li>
                            <a href="/anti-aging-loss-of-firmness">
                              {t("Menu.antiAgingLossOfFirmness")}
                            </a>
                          </li>
                          <li>
                            <a href="/pimples-blemishes">
                              {t("Menu.pimplesBlemishes")}
                            </a>
                          </li>
                          <li>
                            <a href="/dark-circles-puffiness">
                              {t("Menu.darkCirclesPuffiness")}
                            </a>
                          </li>
                          <li>
                            <a href="/nourishment-protection">
                              {t("Menu.nourishmentProtection")}
                            </a>
                          </li>
                          <li>
                            <a href="/oily-skin">{t("Menu.oilySkin")}</a>
                          </li>
                          <li>
                            <a href="/dehydrated-skin">
                              {t("Menu.dehydratedSkin")}
                            </a>
                          </li>
                          <li>
                            <a href="/normal-combination-skin">
                              {t("Menu.normalCombinationSkin")}
                            </a>
                          </li>
                          <li>
                            <a href="/redness">{t("Menu.redness")}</a>
                          </li>
                          <li>
                            <a href="/eye-lip-contour-care">
                              {t("Menu.eyeLipContourCare")}
                            </a>
                          </li>
                          <li>
                            <a href="/body-care">{t("Menu.bodyCare")}</a>
                          </li>
                          <li>
                            <a href="/pigmentation-spots-uniformity-of-the-complexion">
                              {t(
                                "Menu.pigmentationSpotsUniformityOfTheComplexion"
                              )}
                            </a>
                          </li>
                          <li>
                            <a href="/dull-complexion">
                              {t("Menu.dullComplexion")}
                            </a>
                          </li>
                          <li>
                            <a href="/stretch-marks-cellulite">
                              {t("Menu.stretchMarksCellulite")}
                            </a>
                          </li>
                        </ul>
                      )}
                    </li>
                    <li onClick={() => handleSubMenuClick("products4")}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        {t("Menu.boxesKits")}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-chevron-down"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fillRule="evenodd"
                            d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708"
                          />
                        </svg>
                      </div>
                      {activeSubMenu === "products4" && (
                        <ul>
                          <li>
                            <a href="/boxes">{t("Menu.boxes")}</a>
                          </li>
                          <li>
                            <a href="/duo-trio">{t("Menu.duoTrio")}</a>
                          </li>
                        </ul>
                      )}
                    </li>
                  </ul>
                </div>
              )}
            </li>
            <li onClick={() => handleMenuClick("cafeotherapie")}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                {t("Menu.cafeotherapy")}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-chevron-down"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708"
                  />
                </svg>
              </div>
              {activeMenu === "cafeotherapie" && (
                <div className="submenu">
                  <ul>
                    <li>
                      <a href="/la-cafeotherapie">LA CAFEOTHERAPIE</a>
                    </li>
                    <li>
                      <a href="/product/5908">ROUTINE CORPS RAFFERMISSEMENT</a>
                    </li>
                    <li>
                      <a href="/product/5910">ROUTINE VISAGE IMPERFECTIONS</a>
                    </li>

                    <li>
                      <a href="/product/5912">ROUTINE VISAGE ANTI-AGE</a>
                    </li>
                  </ul>
                </div>
              )}
            </li>
            <li onClick={() => handleMenuClick("brandUniverse")}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                {t("Menu.brandUniverse")}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-chevron-down"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708"
                  />
                </svg>
              </div>
              {activeMenu === "brandUniverse" && (
                <div className="submenu">
                  <ul>
                    <li>
                      <a href="/lunivers-de-la-marque#ourHistory">
                        {t("Menu.ourHistory")}
                      </a>
                    </li>
                    <li>
                      <a href="/lunivers-de-la-marque#TheCoffeeBean">
                        {t("Menu.coffeeANaturalTreasure")}
                      </a>
                    </li>
                    <li>
                      <a href="/lunivers-de-la-marque#Simplicity">
                        {t("Menu.theLuxuryOfSimplicity")}
                      </a>
                    </li>
                    <li>
                      <a href="/lunivers-de-la-marque#research">
                        {t("Menu.theResearch")}
                      </a>
                    </li>
                    <li>
                      <a href="/lunivers-de-la-marque#TheCoffeeBean">
                        {t("Menu.ecoFriendlyPackaging")}
                      </a>
                    </li>
                    <li>
                      <a href="/lunivers-de-la-marque#ingredients">
                        {t("Menu.ourIngredients")}
                      </a>
                    </li>
                    <li>
                      <a href="/lunivers-de-la-marque#values">
                        {t("Menu.ourValues")}
                      </a>
                    </li>
                    <li>
                      <a href="/lunivers-de-la-marque#">
                        {t("Menu.keyIngredientCoffee")}
                      </a>
                    </li>
                    <li>
                      <a href="/lunivers-de-la-marque#products">
                        {t("Menu.ourOrganicAndNaturalProducts")}
                      </a>
                    </li>
                    <li>
                      <a href="/lunivers-de-la-marque#caffoetherapy">
                        {t("Menu.coffeeTherapy")}
                      </a>
                    </li>
                    <li>
                      <a href="/lunivers-de-la-marque#package">
                        {t("Menu.bambooOurPackagingChoice")}
                      </a>
                    </li>
                  </ul>
                </div>
              )}
            </li>
            <li>
              <a href="/offres-speciales-produits-de-beaute">
                <div>{t("Menu.specialOffers")}</div>
              </a>
            </li>
            <li>
              <a href="/beaute-secrete">
                <div>{t("Menu.secretBeauty")}</div>
              </a>
            </li>
            <li>
              <a href="/onlineshop">
                <div>{t("Menu.onlineShop")}</div>
              </a>
            </li>
          </ul>
        </div>
      )}
      <div className="menuDiv">
        <a href="/" style={{ textAlign: "center" }}>
          <img id="menuImg" src={menuLogo} alt="test" />
        </a>
        <br />
        <div className="menuItems">
          <div className="dropdown">
            <a href="/">
              {t("Menu.products")} &nbsp;
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-chevron-down"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708"
                />
              </svg>
            </a>
            <div className="dropdown-content">
              <div className="nested-dropdown">
                <a href="/">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    {t("Menu.ranges")} &nbsp;
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-chevron-down"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fillRule="evenodd"
                        d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708"
                      />
                    </svg>
                  </div>
                </a>
                <div className="nested-dropdown-content">
                  <a href="/espresso-range">{t("Menu.espressoRange")}</a>
                  <a href="/moka-range">{t("Menu.mokaRange")}</a>
                  <a href="/macchiato-range">{t("Menu.macchiatoRange")}</a>
                  <a href="/cappuccino-range">{t("Menu.cappuccinoRange")}</a>
                </div>
              </div>
              <div className="nested-dropdown">
                <a href="/">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    {t("Menu.category")} &nbsp;
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-chevron-down"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fillRule="evenodd"
                        d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708"
                      />
                    </svg>
                  </div>
                </a>
                <div className="nested-dropdown-content">
                  <a href="make-up-removers-cleansers">
                    {t("Menu.makeUpRemoversCleansers")}
                  </a>
                  <a href="/exfoliators-scrubs">
                    {t("Menu.exfoliatorsScrubs")}
                  </a>
                  <a href="/masks">{t("Menu.masks")}</a>
                  <a href="/serums">{t("Menu.serums")}</a>
                  <a href="/eye-lip-contour-care">
                    {t("Menu.eyeLipContourCare")}
                  </a>
                  <a href="/body-care">{t("Menu.bodyCare")}</a>
                  <a href="/lightening-anti-dark-spot-care">
                    {t("Menu.lighteningAntiDarkSpotCare")}
                  </a>
                  <a href="/face-creams">{t("Menu.faceCreams")}</a>
                </div>
              </div>
              <div className="nested-dropdown">
                <a href="/">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    {t("Menu.need")} &nbsp;
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-chevron-down"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fillRule="evenodd"
                        d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708"
                      />
                    </svg>
                  </div>
                </a>
                <div className="nested-dropdown-content">
                  <a href="/anti-aging-loss-of-firmness">
                    {t("Menu.antiAgingLossOfFirmness")}
                  </a>
                  <a href="/pimples-blemishes">{t("Menu.pimplesBlemishes")}</a>
                  <a href="/dark-circles-puffiness">
                    {t("Menu.darkCirclesPuffiness")}
                  </a>
                  <a href="/nourishment-protection">
                    {t("Menu.nourishmentProtection")}
                  </a>
                  <a href="/oily-skin">{t("Menu.oilySkin")}</a>
                  <a href="/dehydrated-skin">{t("Menu.dehydratedSkin")}</a>
                  <a href="/normal-combination-skin">
                    {t("Menu.normalCombinationSkin")}
                  </a>
                  <a href="/redness">{t("Menu.redness")}</a>
                  <a href="/eye-lip-contour-care">
                    {t("Menu.eyeLipContourCare")}
                  </a>
                  <a href="/body-care">{t("Menu.bodyCare")}</a>
                  <a href="/pigmentation-spots-uniformity-of-the-complexion">
                    {t("Menu.pigmentationSpotsUniformityOfTheComplexion")}
                  </a>
                  <a href="/dull-complexion">{t("Menu.dullComplexion")}</a>
                  <a href="/stretch-marks-cellulite">
                    {t("Menu.stretchMarksCellulite")}
                  </a>
                </div>
              </div>
              <div className="nested-dropdown">
                <a href="/">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    {t("Menu.boxesKits")} &nbsp;
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-chevron-down"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fillRule="evenodd"
                        d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708"
                      />
                    </svg>
                  </div>
                </a>
                <div className="nested-dropdown-content">
                  <a href="/boxes">{t("Menu.boxes")}</a>
                  <a href="/duo-trio">{t("Menu.duoTrio")}</a>
                </div>
              </div>
            </div>
          </div>
          <div className="dropdown">
            <a href="/la-cafeotherapie">
              {t("Menu.cafeotherapy")} &nbsp;
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-chevron-down"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708"
                />
              </svg>
            </a>
            <div className="dropdown-content">
              <div className="nested-dropdown">
                <a href="/la-cafeotherapie">LA CAFEOTHERAPIE</a>
                <a href="/product/5908">ROUTINE CORPS RAFFERMISSEMENT</a>
                <a href="/product/5910">ROUTINE VISAGE IMPERFECTIONS</a>
                <a href="/product/5912">ROUTINE VISAGE ANTI-AGE</a>
              </div>
            </div>
          </div>
          <div className="dropdown">
            <a href="/lunivers-de-la-marque">
              {t("Menu.brandUniverse")} &nbsp;
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-chevron-down"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708"
                />
              </svg>
            </a>
            <div className="dropdown-content">
              <div className="nested-dropdown">
                <a href="/lunivers-de-la-marque#ourHistory">
                  {t("Menu.ourHistory")}
                </a>
                <a href="/lunivers-de-la-marque#TheCoffeeBean">
                  {t("Menu.coffeeANaturalTreasure")}
                </a>
                <a href="/lunivers-de-la-marque#Simplicity">
                  {t("Menu.theLuxuryOfSimplicity")}
                </a>
                <a href="/lunivers-de-la-marque#research">
                  {t("Menu.theResearch")}
                </a>
                <a href="/lunivers-de-la-marque#TheCoffeeBean">
                  {t("Menu.ecoFriendlyPackaging")}
                </a>
                <a href="/lunivers-de-la-marque#ingredients">
                  {t("Menu.ourIngredients")}
                </a>
                <a href="/lunivers-de-la-marque#values">
                  {t("Menu.ourValues")}
                </a>
                <a href="/lunivers-de-la-marque#">
                  {t("Menu.keyIngredientCoffee")}
                </a>
                <a href="/lunivers-de-la-marque#products">
                  {t("Menu.ourOrganicAndNaturalProducts")}
                </a>
                <a href="/lunivers-de-la-marque#caffoetherapy">
                  {t("Menu.coffeeTherapy")}
                </a>
                <a href="/lunivers-de-la-marque#package">
                  {t("Menu.bambooOurPackagingChoice")}
                </a>
              </div>
            </div>
          </div>
          <div className="dropdown">
            <a href="/offres-speciales-produits-de-beaute">
              {t("Menu.specialOffers")}
            </a>
          </div>
          <div className="dropdown">
            <a href="/beaute-secrete">{t("Menu.secretBeauty")}</a>
          </div>
          <div className="dropdown">
            <a href="/onlineshop">{t("Menu.onlineShop")}</a>
          </div>
        </div>
        <div className="languageSelect">
          <select value={selectedLanguage} onChange={handleLanguageChange}>
            <option value="en">English</option>
            <option value="fr">Français</option>
          </select>
        </div>
      </div>
    </>
  );
}

export default Menu;
