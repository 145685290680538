import React from 'react';
import { useTranslation } from 'react-i18next';

const Payment = () => {
  const { t } = useTranslation();

  return (
    <div style={styles.container}>
      <h1 style={styles.heading}>{t('paymentPage.heading')}</h1>
      <p style={styles.paragraph}>{t('paymentPage.intro')}</p>
      
      <h2 style={styles.subHeading}>{t('paymentPage.paymentMethods')}</h2>
      <p style={styles.paragraph}>
        <strong>{t('paymentPage.cashOnDelivery')}</strong>
      </p>
      
      <h2 style={styles.subHeading}>{t('paymentPage.deliveryTimeframe')}</h2>
      <p style={styles.paragraph}>{t('paymentPage.deliveryInfo')}</p>
      
      <p style={styles.paragraph}>{t('paymentPage.contactUs')}</p>
      <h6><a href="/contactus">{t('paymentPage.contactLink')}</a></h6>
    </div>
  );
};

const styles = {
  container: {
    maxWidth: '800px',
    margin: '2% auto',
    padding: '20px',
    border: '1px solid #ddd',
    borderRadius: '8px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    fontFamily: 'Arial, sans-serif',
    color: '#333'
  },
  heading: {
    color: '#555',
    fontSize: '2em',
    marginBottom: '10px',
  },
  subHeading: {
    color: '#555',
    fontSize: '1.5em',
    marginTop: '20px',
    marginBottom: '10px',
  },
  paragraph: {
    margin: '10px 0',
    lineHeight: '1.6',
    textAlign: 'center'
  },
};

export default Payment;
