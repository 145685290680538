import { useTranslation } from "react-i18next";
import fb from "../assets/imgs/socialMedia/fb.png";
import insta from "../assets/imgs/socialMedia/insta.png";
import x from "../assets/imgs/socialMedia/x.png";
import useLanguage from "../hooks/useLanguage";

function Footer() {
  const { t } = useTranslation();
  useLanguage();

  return (
    <div className="footer">
      <div>
        <h4 className="footerh4">{t("footer.brand")}</h4>
        <h4>
          <a href="/lunivers-de-la-marque">{t("footer.brandUniverse")}</a>
        </h4>
        <h4>
          <a href="/ContactUs">{t("footer.contact")}</a>
        </h4>
        <h4>
          <a href="/payment">{t("footer.payment")}</a>
        </h4>
        <h4>
          <a href="/termsofsales">{t("footer.terms")}</a>
        </h4>
      </div>
      <div>
        <h4 className="footerh4">{t("footer.keepInTouch")}</h4>
        <br />
        <h4>{t("footer.findUsOnSocialNetworks")}</h4>
        <div className="socialMedia">
          <a
            href="https://www.facebook.com/profile.php?id=61552617209978&mibextid=LQQJ4d
"
          >
            <img src={fb} alt="fb" />
          </a>
          <a href="https://www.instagram.com/lecaracoli.lb?igsh=MWE2M3Ztem96cDd2dA==">
            <img src={insta} alt="insta" />
          </a>
          <a href="https://x.com/lecaracolilb?s=21&t=XslsLTvUKnl1RC2EdKIPdg">
            <img src={x} alt="X" />
          </a>
        </div>
      </div>
    </div>
  );
}

export default Footer;
