import "bootstrap/dist/css/bootstrap.css";
import Carousel from "react-bootstrap/Carousel";
import banner1 from "../assets/imgs/Home/banner1.jpg";
import banner2 from "../assets/imgs/Home/banner2.jpg";
import "../assets/Styles/home.css";
import { useTranslation } from "react-i18next";
import useLanguage from "../hooks/useLanguage";

export default function App() {
  const { t } = useTranslation();
  useLanguage();

  return (
    <div>
      <Carousel indicators={false}>
        {" "}
        <Carousel.Item interval={1500}>
          <img className="d-block w-100" src={banner1} alt="First slide" />
          <div className="custom-overlay">
            <h2 style={{ color: "white", textAlign: "start" }}>
              {t("home.offer")}
            </h2>
            <p>{t("home.description")}</p>
            <button>{t("home.seeMore")}</button>
          </div>
        </Carousel.Item>
        <Carousel.Item interval={1500}>
          <img className="d-block w-100" src={banner2} alt="Second slide" />
        </Carousel.Item>
      </Carousel>
    </div>
  );
}
