import "../../assets/Styles/Categories.css";
import ProductList from "../../components/ProductList";
import { useTranslation } from "react-i18next";
import useLanguage from "../../hooks/useLanguage";

function FaceCreams() {
  const { t } = useTranslation();
  useLanguage();

  return (
    <>
      <div id="faceCreamsBanner" className="banner banner-cover">
        <h1>{t("Categories.faceCreamsPage.banner")}</h1>
      </div>
      <div>
        <h3 className="heading1">{t("Categories.faceCreamsPage.heading1")}</h3>
        <p className="paragraph">{t("Categories.faceCreamsPage.paragraph1")}</p>
      </div>
      <ProductList categoryName={"Face creams"} />
    </>
  );
}

export default FaceCreams;
