import ProductList from "../../components/ProductList";
import { useTranslation } from "react-i18next";
import "../../assets/Styles/Categories.css";
import useLanguage from "../../hooks/useLanguage";

function DarkCircles() {
  const { t } = useTranslation();
  useLanguage();

  return (
    <>
      <div id="DarkCirclesBanner" className="banner banner-cover">
        <h1>{t("Needs.darkCirclesPage.banner")}</h1>
      </div>
      <div>
        <h4 className="heading2">{t("Needs.darkCirclesPage.heading1")}</h4>
        <p className="paragraph">{t("Needs.darkCirclesPage.paragraph1")}</p>
      </div>
      <ProductList categoryName={"Dark circles & puffiness"} />
    </>
  );
}

export default DarkCircles;
