import "../../assets/Styles/Categories.css";
import ProductList from "../../components/ProductList";
import { useTranslation } from "react-i18next";
import useLanguage from "../../hooks/useLanguage";

function EyeLip() {
  const { t } = useTranslation();
  useLanguage();

  return (
    <>
      <div id="eyeLipBanner" className="banner banner-cover">
        <h1>{t("Categories.eyeLipPage.banner")}</h1>
      </div>
      <div>
        <h4 className="heading2">{t("Categories.eyeLipPage.heading1")}</h4>
        <p className="paragraph">{t("Categories.eyeLipPage.paragraph1")}</p>
      </div>
      <ProductList categoryName={"Eye & lip contour care"} />
    </>
  );
}

export default EyeLip;
