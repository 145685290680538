import "../../assets/Styles/Ranges/mokaRange.css";
import { useTranslation } from "react-i18next";
import gift1 from "../../assets/imgs/Ranges/mokaRange/gift1.png";
import useLanguage from "../../hooks/useLanguage";
import gift2 from "../../assets/imgs/Ranges/mokaRange/gift2.png";
import ProductList from "../../components/ProductList";

function MokaRange() {
  const { t } = useTranslation();
  useLanguage();

  return (
    <>
      <div id="mokaRangeBanner">
        <h1>{t("mokaRangePage.mokaRangeBannerTitle")}</h1>
        <h2>{t("mokaRangePage.mokaRangeBannerSubtitle")}</h2>
        <h4>{t("mokaRangePage.mokaRangeSection2Text")}</h4>
      </div>

      <div className="center">
        <div id="mokaRangeSection2">
          <div className="end">
            <div className="mokaRangeSection2Text">
              <h3>{t("mokaRangePage.mokaRangeSection2Title")}</h3>
              <p>{t("mokaRangePage.mokaRangeSection2Text")}</p>
            </div>
          </div>
          <div>
            <div className="mokaRangeSection2Text">
              <h3>{t("mokaRangePage.mokaRangeSection2Title")}</h3>
              <p>{t("mokaRangePage.mokaRangeSection2Text")}</p>
            </div>
          </div>
        </div>
      </div>

      <div>
        <h1 style={{ fontSize: "34px", color: "#474747", marginTop: "3%" }}>
          {t("mokaRangePage.mokaRangeDuesTitle")}
        </h1>
        <p style={{ fontSize: "18px", textAlign: "center", color: "#474747" }}>
          {t("mokaRangePage.mokaRangeMagnifyBeauty")}
        </p>
      </div>

      <div id="mokaRangeBanner2">
        <h1>{t("mokaRangePage.mokaRangeBanner2Title")}</h1>
        <br />
        <div className="center">
          <div className="centerColumn">
            <p>{t("mokaRangePage.mokaRangeLipBalm")}</p>
            <a href="/product/5681">
              <button>{t("mokaRangePage.mokaRangeLipBalmOrder")}</button>
            </a>
          </div>
          <div className="centerColumn">
            <p>{t("mokaRangePage.mokaRangeNourishingCare")}</p>
            <a href="/product/5684">
              <button>{t("mokaRangePage.mokaRangeLipBalmOrder")}</button>
            </a>
          </div>
        </div>
      </div>

      <div>
        <h1>{t("mokaRangePage.mokaRangeBanner3Title")}</h1>
        <h4>{t("mokaRangePage.mokaRangeGiftDescription")}</h4>

        <div className="center gap">
          <img src={gift1} alt="" width={450} />
          <div className="centerColumn2">
            <p>NEW</p>
            <h3>{t("mokaRangePage.mokaRangeEssentialSetTitle")}</h3>
            <p>{t("mokaRangePage.mokaRangeEssentialSetDescription")}</p>
            <a href="/product/5617">
              {t("mokaRangePage.mokaRangeEssentialSetBuy")}
            </a>
          </div>
        </div>
        <div className="center gap">
          <div className="centerColumn2">
            <p>NEW</p>
            <h3>{t("macchiatoRangePage.gift1.title")}</h3>
            <p>{t("macchiatoRangePage.gift1.description")}</p>
            <a href="/product/5696">{t("macchiatoRangePage.gift1.buy")}</a>
          </div>
          <img src={gift2} alt="" width={450} />
        </div>
      </div>

      <div>
        <h1>{t("mokaRangePage.mokaRangeRitualTitle")}</h1>
        <h4>{t("mokaRangePage.mokaRangeRitualDescription")}</h4>
      </div>

      <div id="mokaRangeBanner3">
        <h1 style={{ fontSize: "60px", paddingTop: "3%" }}>
          {t("mokaRangePage.mokaRangeDuesTitle")}
        </h1>
      </div>

      <div>
        <h4
          className="center-text"
          style={{ fontSize: "25px", fontWeight: "600", marginTop: "3%" }}
        >
          {t("mokaRangePage.mokaRangeMagnifyBeauty")}
        </h4>
        <h4
          className="center-text"
          style={{ fontSize: "25px", marginBottom: "3%" }}
        >
          {t("mokaRangePage.mokaRangeDiscoverSelections")}
        </h4>
      </div>
      <ProductList categoryName={"Moka range"} />
    </>
  );
}

export default MokaRange;
