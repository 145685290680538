import useLanguage from "../hooks/useLanguage";
import HomeBanner from "../components/HomeBanner";
import "../assets/Styles/home.css";
import Slider from "react-slick";
import brandUniverse1 from "../assets/imgs/Home/brandUniverse/5X4A0045.jpg";
import brandUniverse2 from "../assets/imgs/Home/brandUniverse/5X4A0077.jpg";
import brandUniverse3 from "../assets/imgs/Home/brandUniverse/5X4A0078.jpg";
import brandUniverse4 from "../assets/imgs/Home/brandUniverse/5X4A0089.jpg";
import brandUniverse5 from "../assets/imgs/Home/brandUniverse/5X4A0097.jpg";
import brandUniverse6 from "../assets/imgs/Home/brandUniverse/5X4A0099-Recovered.jpg";
import brandUniverse7 from "../assets/imgs/Home/brandUniverse/5X4A0117.jpg";
import brandUniverse8 from "../assets/imgs/Home/brandUniverse/5X4A0147.jpg";
import brandUniverse9 from "../assets/imgs/Home/brandUniverse/5X4A9920.jpg";
import brandUniverse10 from "../assets/imgs/Home/brandUniverse/5X4A9964.jpg";
import coffeeTherapy1 from "../assets/imgs/Home/coffeeTherapy/5X4A9910.jpg";
import coffeeTherapy2 from "../assets/imgs/Home/coffeeTherapy/5X4A9964-1.jpg";
import coffeeTherapy3 from "../assets/imgs/Home/coffeeTherapy/5X4A9973.jpg";
import coffeeTherapy4 from "../assets/imgs/Home/coffeeTherapy/5X4A9993.jpg";
import beautyBoxes1 from "../assets/imgs/Home/beautyBoxes/test.jpg";
import beautyBoxes2 from "../assets/imgs/Home/beautyBoxes/WhatsApp-Image-2024-05-16-at-21.21.17_4bf898e5.jpg";
import beautyBoxes3 from "../assets/imgs/Home/beautyBoxes/WhatsApp-Image-2024-05-16-at-21.21.19_17f61078.jpg";
import beautyBoxes4 from "../assets/imgs/Home/beautyBoxes/WhatsApp-Image-2024-05-16-at-21.21.19_ce2ea966.jpg";
import footerImg from "../assets/imgs/Home/footerImg.png";
import { useTranslation } from "react-i18next";

const Home = () => {
  const { t } = useTranslation();
  useLanguage();

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  const settings1 = {
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  const settings2 = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  return (
    <div className="homeDiv">
      <HomeBanner />
      <h1 className="brandUniverseH sectionHeadH">
        {t("home.brandUniverse.title")}
      </h1>
      <p className="brandUniverseP sectionHeadP">
        {t("home.brandUniverse.description")}
      </p>
      <div className="sliderDiv">
        <Slider {...settings} id="brandUniverse">
          <div>
            <img
              className="brandUniverseImg"
              src={brandUniverse1}
              alt="le-caracoli"
            />
          </div>
          <div>
            <img
              className="brandUniverseImg"
              src={brandUniverse2}
              alt="le-caracoli"
            />
          </div>
          <div>
            <img
              className="brandUniverseImg"
              src={brandUniverse3}
              alt="le-caracoli"
            />
          </div>
          <div>
            <img
              className="brandUniverseImg"
              src={brandUniverse4}
              alt="le-caracoli"
            />
          </div>
          <div>
            <img
              className="brandUniverseImg"
              src={brandUniverse5}
              alt="le-caracoli"
            />
          </div>
          <div>
            <img
              className="brandUniverseImg"
              src={brandUniverse6}
              alt="le-caracoli"
            />
          </div>
          <div>
            <img
              className="brandUniverseImg"
              src={brandUniverse7}
              alt="le-caracoli"
            />
          </div>
          <div>
            <img
              className="brandUniverseImg"
              src={brandUniverse8}
              alt="le-caracoli"
            />
          </div>
          <div>
            <img
              className="brandUniverseImg"
              src={brandUniverse9}
              alt="le-caracoli"
            />
          </div>
          <div>
            <img
              className="brandUniverseImg"
              src={brandUniverse10}
              alt="le-caracoli"
            />
          </div>
        </Slider>
      </div>
      <h1 className="CoffeeTherapyH sectionHeadH">
        {t("home.coffeeTherapy.title")}
      </h1>
      <p className="CoffeeTherapyP sectionHeadP">
        {t("home.coffeeTherapy.description")}
      </p>
      <div className="sliderDiv">
        <Slider {...settings1} id="CoffeeTherapy">
          <div>
            <img
              className="CoffeeTherapyImg"
              src={coffeeTherapy1}
              alt="le-caracoli"
            />
          </div>
          <div>
            <img
              className="CoffeeTherapyImg"
              src={coffeeTherapy2}
              alt="le-caracoli"
            />
          </div>
          <div>
            <img
              className="CoffeeTherapyImg"
              src={coffeeTherapy3}
              alt="le-caracoli"
            />
          </div>
          <div>
            <img
              className="CoffeeTherapyImg"
              src={coffeeTherapy4}
              alt="le-caracoli"
            />
          </div>
        </Slider>
      </div>
      <h1 className="brandUniverseH sectionHeadH">
        {t("home.beautyBoxes.title")}
      </h1>
      <p className="brandUniverseP sectionHeadP">
        {t("home.beautyBoxes.description")}
      </p>
      <div className="centered">
        <div id="sliderDiv3">
          <Slider {...settings2} id="beautyBoxes">
            <div>
              <img
                className="beautyBoxesImg"
                src={beautyBoxes1}
                alt="le-caracoli"
              />
            </div>
            <div>
              <img
                className="beautyBoxesImg"
                src={beautyBoxes2}
                alt="le-caracoli"
              />
            </div>
            <div>
              <img
                className="beautyBoxesImg"
                src={beautyBoxes3}
                alt="le-caracoli"
              />
            </div>
            <div>
              <img
                className="beautyBoxesImg"
                src={beautyBoxes4}
                alt="le-caracoli"
              />
            </div>
          </Slider>
        </div>
      </div>
      <h1 className="followUsH sectionHeadH">{t("home.instagram.title")}</h1>
      <div className="centered">
        <img src={footerImg} alt="insta" id="followUsImg" />
      </div>
      <br />
    </div>
  );
};

export default Home;
